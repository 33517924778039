
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },

    category: {
      type: Object,
      required: true,
    },
  },

  computed: {
    pagesExists() {
      return Array.isArray(this.data.pages) && this.data.pages.length
    },

    moreThanOnePage() {
      return Array.isArray(this.data.pages) && this.data.pages.length > 1
    },
  },

  async mounted() {
    await this.$nextTick()
  },

  methods: {
    initAnimation() {
      const q = this.$gsap.utils.selector(this.$el)

      const tl = this.$gsap.timeline({
        onComplete: () => this.$nuxt.$emit('hero-animated'),
        paused: true,
      })

      if (q('.fade-in')) {
        tl.add(
          this.$gsap.to(q('.fade-in'), {
            opacity: 1,
            duration: 0.2,
            stagger: 0.2,
          })
        )
      }

      if (q('.slide-in')) {
        tl.add(
          this.$gsap.to(q('.slide-in'), {
            y: 0,
            delay: 0.3,
            opacity: 1,
            stagger: 0.15,
          }),
          '<30%'
        )
      }

      tl.play()
    },

    heroImageLoaded() {
      this.initAnimation()
    },
  },
}
