
export default {
  methods: {
    scrollDown() {
      const selector = '[scroll-to-id="1"]'
      const element = document.querySelector(selector)

      if (!element) {
        return
      }

      const headerOffset = 90
      const elementPosition = element?.getBoundingClientRect().top
      const offsetPosition = elementPosition - headerOffset

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    },
  },
}
